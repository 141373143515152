<template>
  <div
    class="
      d-flex
      bgi-position-y-bottom
      position-x-center
      bgi-no-repeat bgi-size-contain bgi-attachment-fixed
    "
  >
    <!--begin::Content-->
    <div class="container">
      <div class="row p-12 pb-lg-20">
        <div class="col-6">
          <div class="card-sprite-left">
            <img
              :src="require('@/assets/images/bg-login.png')"
              alt=""
              class="stripe-left-img w-100"
            />
          </div>
        </div>
        <div class="col-6">
          <div class="d-flex align-items-center h-100">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "auth",
  components: {},
  setup() {
    const store = useStore();

    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });
  },
});
</script>
<style lang="scss" scoped>
.card-stripe-left {
  padding: 15px;
  border-radius: 20px;
  background-color: black;
}
.stripe-left-img {
  border-radius: 20px;
}
.gws-logo-center {
  width: 277px;
  height: 72px;
}
</style>
